<template>
  <div class="mobileh">
    <div class="mobile_header">
      <div class="head">
        <div class="logo"><img src="../img/mobilelogo.png"></div>
        <div class="navbtn" @click="gotonav"><img src="../img/mobilenavlist.png"></div>
      </div>
      <div class="headimg">
        <img src="../img/mobileheadimg.png">
      </div>
      <div class="headtext">
        <img src="../img/headtitletext.png" />
      </div>
      <!-- <div style="margin-bottom: 10px;" class="headbtn" @click="Login">Login <img src="../img/mbl_godown.png" /></div> -->
      <div class="headbtn" @click="downLoadAPK">Download <img src="../img/mbl_godown.png" /></div>
    
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapState(['navshow'])
  },
  methods: {
    ...mapMutations(['changenavshow']),
    gotonav() {
      // this.$refs.shownav.style.display = 'block'
      // this.changenavshow(true)
      this.$router.push('/nav')
    },
    closenav() {
      this.changenavshow(false)
      // this.$refs.shownav.style.display = 'none'
    },
    gotoabout() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    },
    downLoadAPK() {
      // window.open('./apps/wakapro/wakapro_release.apk', '_blank');
      var timestamp = Date.parse(new Date());
      var apklink = `./apps/Adda365-Release.apk?t=${timestamp}`;
      //----- 创建隐藏的可下载链接
      var eleLink = document.createElement('a');
      eleLink.setAttribute("href", apklink);
      eleLink.setAttribute("download", 'Adda365-Release.apk');
      eleLink.style.display = 'none';
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    Login() {
      window.open('https://paypage.adda365.app', '_blank');
    },

  }
}
</script>

<style lang="scss" scoped src="./mobileheader.scss"></style>