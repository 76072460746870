<template>
  <div class="content">
    <div class="bg1">
      <div class="wrap">
        <div
          class="infous mg wow animate__fadeInUp"
          data-wow-duration="0.5s"
          data-wow-delay="0s"
          id="about"
        >
          <div class="l">
            <img src="../img/infousimg.png" />
          </div>
          <div class="r">
            <p
              style="
                font-size: 18px;
                font-family: Mulish-Bold;
                color: rgba(255, 241, 8, 1);
                margin-bottom: 16px;
              "
            >
              CUBETECH INNOVATIONS PRIVATE LIMITED
            </p>
            <p class="text_title" style="margin-bottom: 16px">Who Are We?</p>
            <!-- <p class="text_content">
              Is your life boring? Want to make friends worldwide? Need
              something funny and meaningful? Adda365 is what you are looking
              for!
            </p> -->
            <p class="text_content">
              India’s biggest voice-centric entertainment platform. We not only
              provide you with a platform to connect but to voice out your
              thoughts and talents; to hear and be heard. You can also try to
              look for your new friends in Adda365
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg2">
      <div class="wrap">
        <div
          class="infocan mg wow animate__fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0s"
        >
          <!-- <div class="l">
            <img src="../img/infocanimg.png">
          </div>
          <div class="r">
            <p class="text_title">What you can do on StarMake?</p>
            <p style="margin:.16rem 0;font-size: 36px;font-family: Mulish-Bold; color: rgba(0, 216, 219, 1);">ALL OF
              THESE!!</p>
            <p class="text_sectitle">🎉 Voice Chat Room🎉</p>
            <p class="text_content" style="margin:20px 0">
              Party & Chat in your favourite room. Join group voice chat room or create your own live chat.
            </p>
            <p class="text_sectitle">💬 Private Converstation💬</p>
            <p class="text_content" style="margin:20px 0">
              Start voice chat with friends to talk about your interests.</p>
            <p class="text_content" >
              Enjoy yourself! StarMake wish everyone in our community to showcase yourself, and stay connected in a
              free, friendship, and respect way.</p>
          </div> -->
          <p class="text_title">What you can do on Adda365?</p>
          <img class="allofthese" src="../img/allofthese.png" />
          <ul class="canlist">
            <li class="canitem"><img src="../img/canitem1.png" /></li>
            <li class="canitem"><img src="../img/canitem2.png" /></li>
            <li class="canitem"><img src="../img/canitem3.png" /></li>
            <li class="canitem"><img src="../img/canitem4.png" /></li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="bg3">
      <div class="wrap">
        <div class="chatnow mg wow animate__fadeInUp" data-wow-duration="1s" data-wow-delay="0s">
          <p class="text_title" style="margin-bottom:36px;text-align: center;">
            Let's Play Now
          </p>
          <div class="swp">
           
            <el-carousel type="card" height="541px" class="paly_carousel" :interval="1000000">
              <el-carousel-item v-for="(item) in carImgList" :key="item">
                <img :src="item" class="medium" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div> -->
    <div class="bg5">
      <div class="wrap">
        <div
          class="mg wow animate__fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0s"
        >
          <p class="text_title" style="margin-bottom: 36px">
            Media Information
          </p>
          <div
            class="media_dom"
            v-for="(item, index) in MediaList"
            :key="`Media${index}`"
            @click="
              () => {
                OpenLink(index);
              }
            "
          >
            <div class="media_info">
              <p class="media_title">{{ item.title }}</p>
              <p class="media_des">{{ item.des }}</p>
              <p class="media_from">{{ item.from }}</p>
            </div>
            <img class="media_img" :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="bg4">
      <div class="wrap">
        <div
          class="contectus mg wow animate__fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0s"
          id="contact"
        >
          <p class="text_title" style="text-align: left">Contact Us</p>
          <p style="font-family: Mulish-Bold; margin: 16px 0 8px">
            CUBETECH INNOVATIONS PRIVATE LIMITED
          </p>
          <p
            style="font-family: Mulish-Medium; color: rgba(255, 255, 255, 0.6)"
          >
            <span style="margin-right: 16px"
              >Customer care number: +91 98704 23159</span
            ><span>Customer care email ID: contact@Adda365.app</span>
          </p>
          <p
            style="
              margin-top: 8px;
              font-family: Mulish-Medium;
              color: rgba(255, 255, 255, 0.6);
            "
          >
            Address:3RD FLOOR, B-8, SECTOR 2 NOIDA, Noida, Gautam Buddha Nagar,
            Uttar Pradesh, 201301
          </p>
          <div class="input_concent">
            <div class="all_item">
              <p>Name</p>
              <div class="input_item">
                <!-- <img src="../img/user_input.png" alt="" width="100%"> -->
                <input
                  type="text"
                  v-model="insertInfo.user"
                  placeholder="Please enter name"
                />
              </div>
            </div>
            <div class="all_item">
              <p>E-mail</p>
              <div class="input_item">
                <!-- <img src="../img/email_input.png" alt="" width="100%"> -->
                <input
                  type="text"
                  v-model="insertInfo.email"
                  placeholder="Please enter E-mail"
                />
              </div>
            </div>

            <div class="all_item">
              <p>Phone</p>
              <div class="input_item">
                <!-- <img src="../img/phone_input.png" alt="" width="100%"> -->
                <input
                  type="text"
                  v-model="insertInfo.phone"
                  placeholder="Please enter Phone"
                />
              </div>
            </div>
          </div>
          <p style="margin-bottom: 6px">Message</p>
          <textarea
            class="message_input"
            type="textarea"
            placeholder="Please enter Message"
            v-model="insertInfo.message"
          ></textarea>
          <el-button class="send" :plain="true" @click="thro">Send</el-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  watch: {
    $route: {
      handler(val) {
        if (this.$route.query['contact']) {
          this.toConcatUs();
        }
        if (this.$route.query['about']) {
          this.toAboutus();
        }
      },
    },
  },
  data() {
    return {
      carImgList: [
        require('../img/car_img1.png'),
        require('../img/car_img2.png'),
        require('../img/car_img3.png'),
      ],
      insertInfo: {
        user: '',
        email: '',
        phone: '',
        message: '',
      },
      timer: null,
      flag: false,
      MediaList: [
        {
          title:
            'Surge In Use Of Short Format Videos Leads To Birth Of Influencers, Multiple Apps',
          des: 'As a result, a wave of influencers has risen, leveraging these platforms to amass substantial followings, engage diverse audiences, and wield significant influence over consumer behavior and trends.',
          from: 'From: ZEENEWS',
          link: 'https://zeenews.india.com/internet-social-media/surge-in-use-of-short-format-videos-leads-to-birth-of-influencers-multiple-apps-adda365-2699985.html',
          img: require('../img/media1.png'),
        },
        {
          title:
            'Largest voice-centric entertainment platform Adda365 launches its new app feature ‘Audio Room’',
          des: 'Adda365 has given a platform to video creators from Metro cities and voice room users from smaller towns and cities. This will help people from smaller towns to get a wider platform and showcase their talent.',
          from: 'From: Outlook',
          link: 'https://www.outlookindia.com/business-spotlight/largest-voice-centric-entertainment-platform-adda365-launches-its-new-app-feature-audio-room--news-233734',
          img: require('../img/media2.png'),
        },
        {
          title:
            'Adda 365: India’s Biggest Video-centric Entertainment Platform Now Brings the Audio Room Feature',
          des: 'Today, short-form videos are everywhere. Every big and small platform is now in this race of attracting more users using short-form content, from Instagram to YouTube and to Facebook. In India, the short video market has seen a significant boost over the past two or three years. The COVID-19 pandemic, furthermore, acted as a catalyst in this process.',
          from: 'From: DAILYEXCELSIOR',
          link: 'https://www.dailyexcelsior.com/adda-365-indias-biggest-video-centric-entertainment-platform-now-brings-the-audio-room-feature/',
          img: require('../img/media3.png'),
        },
        {
          title:
            "Join the Conversation: Adda365's Audio Rooms Creating Community",
          des: 'Mumbai: In thе vast landscapе of digital connеctivity, social mеdia еmеrgеs as intеractivе tеchnologiеs, facilitating thе crеation and sharing of divеrsе contеnt and idеas within virtual communitiеs and nеtworks. Dеspitе challеngеs in prеcisеly dеfining social mеdia duе to thе prolifеration of stand-alonе and intеgratеd sеrvicеs, common fеaturеs pеrsist. Usеrs prеdominantly accеss thеsе platforms via wеb-basеd applications or mobilе downloads, transforming еlеctronic spacеs into highly intеractivе hubs for sharing, co-crеating, discussing, and modifying usеr-gеnеratеd contеnt.',
          from: 'From: FIRST INDIA',
          link: 'https://firstindia.co.in/news/press-releases/join-the-conversation-adda365s-audio-rooms-creating-community',
          img: require('../img/media4.png'),
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query['contact']) {
      this.toConcatUs();
    }
    if (this.$route.query['about']) {
      this.toAboutus();
    }
    new this.$wow.WOW({
      live: true,
    }).init();
  },
  methods: {
    OpenLink(index) {
      var link = this.MediaList[index].link;
      console.log('link-->', link);
      // return;
      var eleLink = document.createElement('a');
      eleLink.setAttribute('href', link);
      eleLink.style.display = 'none';
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    toConcatUs() {
      document.getElementById(`contact`).scrollIntoView();
      this.$router.push('/');
    },
    toAboutus() {
      document.getElementById(`about`).scrollIntoView();
      this.$router.push('/');
    },

    throttle(func, wait = 300, immediate = true) {
      if (immediate) {
        if (!this.flag) {
          this.flag = true;
          // 如果是立即执行，则在wait毫秒内开始时执行
          typeof func === 'function' && func();
          this.timer = setTimeout(() => {
            this.flag = false;
          }, wait);
        }
      } else {
        if (!flag) {
          this.flag = true;
          // 如果是非立即执行，则在wait毫秒内的结束处执行
          this.timer = setTimeout(() => {
            this.flag = false;
            typeof func === 'function' && func();
          }, wait);
        }
      }
    },
    thro() {
      this.throttle(this.SendMessage, 5000);
      // this.throttle(this.SendMessage, 1000)
    },

    SendMessage() {
      console.log('sendddd');
      for (let key in this.insertInfo) {
        if (this.insertInfo[key] == '') {
          this.$message({
            message: 'Please fill in all the information.',
            type: 'warning',
          });
          return;
        }
      }
      this.$message({
        message: 'send successfully',
        type: 'success',
        //type: warning
        // this.$message.error('shibai');
      });
      this.insertInfo.user = '';
      this.insertInfo.email = '';
      this.insertInfo.phone = '';
      this.insertInfo.message = '';
    },
  },
};
</script>

<style lang="scss" scoped src="./content.scss">
</style>