<template>
  <div>
    <Mdetail :deteil_title="'Refund Policy'"></Mdetail>
    <div class="detail_text_box detail_text">
      <p>
        The event any in-app purchases and virtual items require a transaction
        with a third party service provider (including payment service
        providers), the Visitor agrees to abide by the terms of use and other
        relevant instruction of such third party service provider.
      </p>
      <p>
        The Visitor shall be solely responsible for any payments made from his
        bank account in this relation. The Company shall not be a party to such
        transaction or agreement, and shall not be responsible for any actions
        or omission, claims of misconduct, fraud or negligence pertaining to the
        transaction.
      </p>
      <p>
        We want you to be satisfied with your purchases on Adda365.app . If
        you're not entirely happy with your in-app purchases or virtual items,
        here's what you need to know:
      </p>
      <p>You have 7 days from the date of purchase to request a refund.</p>
      <p>
        To request a refund within this period, reach out to our customer
        support team at contact@adda365.app with details of your transaction and
        the reason for your refund request.
      </p>
      <p>
        The Company accepts no obligation and responsibility for the use of the
        payment option by the third party service provider. Once payment is made
        by the Visitor, the Visitor shall not have the further right to cancel
        and redeem, or require a refund of the payment already made by him.
      </p>
    </div>
    <Mobilefooter></Mobilefooter>
  </div>
</template>

<script>
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue';
import Mdetail from '@/components/Mdetail/Mdetail.vue';
export default {
  components: {
    Mdetail,
    Mobilefooter,
  },
};
</script>

<style>
</style>