<template>
  <div class="mobile_home">
    <Mobileheader></Mobileheader>
    <Mobilecontent></Mobilecontent>
    <Mobilefooter></Mobilefooter>
  </div>
</template>

<script>
import Mobileheader from '@/components/MobileHeader/Mobileheader.vue'
import Mobilecontent from '@/components/MobileContent/Mobilecontent'
import Mobilefooter from '@/components/MobileFooter/Mobilefooter.vue'
export default {
  components: {
    Mobileheader,
    Mobilecontent,
    Mobilefooter,
  }

}
</script>

<style lang="scss" scoped src="./mobile.scss">
</style>