<template>
  <div class="header">
    <div class="hbg">
      <div class="header_nav wrap">
        <div class="l" @click="gotohome">
          <a href="">
            <img class="logo" src="../img/logo.png" />
          </a>
        </div>
        <div class="r">
          <Nav :hrefList="hrefList"></Nav>
        </div>
      </div>

      <div class="header_body wrap">
        <div class="l">
          <div class="header_text">
            <img src="../img/head_text.png" />
          </div>
          <!-- <div class="freedown" @click="Login">Login <img src="../img/godown.png" /></div> -->
          <div class="freedown" style="cursor:pointer" @click="downLoadAPK">Download <img src="../img/godown.png" /></div>
        </div>
        <div class="r">
          <img src="../img/header_img.png">
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
export default {
  data() {
    return {
      hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
        'Contact Us', 'Refund Policy'],
      // hrefList: ['About Us', 'Terms of Service', 'Privacy Policy',
      // 'Contact Us', 'Refund Policy', 'Cancellation Policy']
    }
  },
  components: {
    Nav
  },
  methods: {
    gotohome() {
      console.log('home');
      this.$router.push('/')
    },
    downLoadAPK() {
      // window.open('./apps/wakapro/wakapro_release.apk', '_blank');
      var timestamp = Date.parse(new Date());
      var apklink = `./apps/Adda365-Release.apk?t=${timestamp}`;
      //----- 创建隐藏的可下载链接
      var eleLink = document.createElement('a');
      eleLink.setAttribute("href", apklink);
      eleLink.setAttribute("download", 'Adda365-Release.apk');
      eleLink.style.display = 'none';
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
    Login() {
      window.open('https://paypage.adda365.app', '_blank');
    },
  }
}
</script>

<style lang="scss" scoped src="./header.scss">

</style>